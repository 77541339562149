import MFEContainer from "./containers/MFEContainer";
import { UserInfo } from "./types/UserInfo";

export interface Props {
  language: string;
  userInfo: UserInfo;
}

function App({ language, userInfo }: Props) {
  return (
    <MFEContainer language={language}>
      Tax Center
      <div>{JSON.stringify(userInfo)}</div>
      <div>{language}</div>
    </MFEContainer>
  );
}

export default App;
